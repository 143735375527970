import Typography, { ITypo } from "src/Components/Materials/Typography";

import classes from "./styles.module.scss";

type IProps = {
	title: string;
	align: "flex-end" | "flex-start";
};

export default function BlueBlock({ title, align }: IProps) {
	return (
		<div className={classes["root"]} style={{ justifyContent: align }}>
			<div className={classes["container"]}>
				<div className={classes["title"]}>
					<Typography typo={ITypo.H3_TITLE}>{title}</Typography>
				</div>
			</div>
		</div>
	);
}
