import { useCallback } from "react";

import BurgerMenu from "../Materials/BurgerMenu";
import Button, { EButtonSize, EButtonVariant } from "../Materials/Button";
import Typography, { ITypo, ITypoColor } from "../Materials/Typography";
import classes from "./style.module.scss";

export default function Header() {
	const goToWishlistHash = useCallback(() => {
		window.location.hash = "wishlist";
	}, []);

	return (
		<header className={classes["header"]}>
			<div className={classes["header-container"]}>
				<div className={classes["logo"]}>
					<a href="#">
						<img loading="lazy" className={classes["logo-img"]} src="./assets/logo.png" alt="website logo" />
					</a>
				</div>
				<div className={classes["links-container"]}>
					<a href="#wishlist-top" className={classes["link"]}>
						TheXP
					</a>
					<a href="#home" className={classes["link"]}>
						World Of The Machine
					</a>
					<a href="#features" className={classes["link"]}>
						Innovations & world first
					</a>
					<a href="#team" className={classes["link"]}>
						Team
					</a>
					<a href="#tokenomics" className={classes["link"]}>
						Mint Revenues Breakdown
					</a>
					<a href="#roadmap" className={classes["link"]}>
						Roadmap
					</a>
				</div>
				<div className={classes["connect-button"]}>
					<Button onClick={goToWishlistHash} variant={EButtonVariant.PRIMARY} sizing={EButtonSize.SMALL}>
						Join the Wishlist
					</Button>
				</div>
				<div className={classes["burger-container"]}>
					<BurgerMenu />
				</div>
			</div>
		</header>
	);
}
