import Anchor from "src/Components/Materials/Anchor";

import Button, { EButtonVariant } from "../../Materials/Button";
import Typography, { ITypo, ITypoColor } from "../../Materials/Typography";
import styles from "./styles.module.scss";

export default function WishlistTop() {
	return (
		<>
			<div className={styles["root"]}>
				<div className={styles["background-container"]}>
					<div className={styles["section-container"]}>
						<div className={styles["content-container"]}>
							<div className={styles["content-left"]}>
								<div className={styles["content-title"]}>
									<Anchor id="wishlist-top" />
									<Typography typo={ITypo.H2_TITLE} color={ITypoColor.WHITE}>
										JOIN THE WISHLIST
									</Typography>
								</div>
								<div className={styles["content-text"]}>
									<p>
										• Private Sale: <span className={styles["sale-date"]}>August 2023</span>
									</p>
									<p>
										• PreSale: <span className={styles["sale-date"]}>September 2023</span>
									</p>
									<p>
										• Public Sale: <span className={styles["sale-date"]}>October 2023</span>
									</p>
								</div>
								<div className={styles["content-text"]}>I'm In ! Keep me informed about the project.</div>
							</div>
							<div className={styles["content-right"]}>
								<img loading="lazy" src="./assets/watch.png" alt="a watch"></img>
							</div>
						</div>
						<div className={styles["section-bottom"]}>
							<Button variant={EButtonVariant.PRIMARY}>
								<a href="#wishlist">Elevate My Experience</a>
							</Button>
						</div>
					</div>
					<img src="./assets/sections/wishlist-top/top-lines.png" alt="top lines" className={styles["top-lines"]}></img>
				</div>
			</div>
		</>
	);
}
