import Anchor from "src/Components/Materials/Anchor";
import Typography, { ITypo } from "src/Components/Materials/Typography";

import FeatureContainer from "./FeatureContainer";
import classes from "./styles.module.scss";

export default function Features() {
	return (
		<div className={classes["root"]}>
			<video autoPlay muted loop className={classes["background-video"]}>
				<source src="assets/gradient2.webm" type="video/webm" />
			</video>
			<div className={classes["background-container"]}>
				<div className={classes["section-container"]}>
					<div className={classes["text-container"]}>
						<div className={classes["title"]}>
							<Anchor id="features" />
							<Typography typo={ITypo.H2_TITLE}>A NFT SERIES like you've never seen before</Typography>
						</div>
						<div className={classes["text"]}>
							<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
								World Of The Machines is based on nothing less than 11 exclusive innovations, including the worlwide first ASMR connected Fidget
								using a unique magneticbezel and a set of crypto coins officially stamped by "La Monnaie de Paris".
							</Typography>
						</div>
					</div>
					<div className={classes["features-container"]}>
						<FeatureContainer
							title="A unique Fidget"
							subtitle={
								<p>
									Complementary to the digital side of the PassForLife, the fidget ("Fingers + Gadget") is an ASMR limited edition real object
									incorporating a custom NFC (Near Field Chip) created by a Swiss luxury watch designer to be used with your smartphone and
									get specific advantages. More to come soon... 😉
								</p>
							}
							picto="./assets/sections/features/1.png"
						/>
						<FeatureContainer
							title="Social Interactions"
							subtitle={
								<p>
									Imagine being rewarded for every real life social interactions you have with other fidget holders : the fidget gives the
									ability to trigger a quantic connexion between PassForLife hodlers, resulting in #NRJ generation and a potential enhancement
									of one or both Pass(es)...
								</p>
							}
							picto="./assets/sections/features/2.png"
						/>
						<FeatureContainer
							title="Semi-organic AI based QR Code"
							subtitle={
								<p>
									The PassForLife design is not another NTF jpeg: its design has been produced by an AI, and is based on a cross between a
									"Human" organic vibe, and a "Machine’s" digital approach. Traits and rarity are secretly encoded in the design, you will
									have to discover how..."
								</p>
							}
							picto="./assets/sections/features/3.png"
						/>
						<FeatureContainer
							title="Gamma Paper"
							subtitle={
								<p>
									Because your PassForLife and Fidget have been designed by the Singularity itself, and are based on quantic mechanic
									principles, the document detailing all the project can't be limited to humans visible wavelength: it is a GammaPaper,
									encoded in the Machines exclusive encrypted language. Those capable of deciphering this enigma will be heavily rewarded...
									More to come soon...
								</p>
							}
							picto="./assets/sections/features/4.png"
						/>
					</div>
				</div>
				<img src="./assets/sections/features/separator.png" className={classes["separator"]} />
				<img src="./assets/sections/features/xxx.png" className={classes["xxx"]} />
			</div>
		</div>
	);
}
