import { useContext, createContext } from "react";

export enum EStatus {
    OPEN = "open",
    CLOSED = "closed",
    NOTHING = "nothing",
}

export type IMobileMenuContext = {
	status: EStatus;
	setStatus: (status: EStatus) => void;
};

export const MobileMenuContext = createContext<IMobileMenuContext>({
	status: EStatus.NOTHING,
	setStatus: (status: EStatus) => {},
});

export const useMobileMenuContext = () => useContext(MobileMenuContext);
