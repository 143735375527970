import { useCallback, useState } from "react";
import classes from "./style.module.scss";
import { EStatus, useMobileMenuContext } from "src/Contexts/MobileMenuContext";

const isTouchEvents = /Android|iPhone/i.test(navigator.userAgent);

export default function BurgerMenu() {
	const { status, setStatus } = useMobileMenuContext();

	const toggle = useCallback(() => {
		if (status === EStatus.OPEN) return setStatus(EStatus.CLOSED);
		if (status === EStatus.NOTHING || status === EStatus.CLOSED) return setStatus(EStatus.OPEN);
	}, [status]);

	if (isTouchEvents) {
		return <img src="./assets/burger.svg" alt="burger menu" className={classes["root"]} data-open={status} onTouchEnd={toggle} />;
	}

	return <img src="./assets/burger.svg" alt="burger menu" className={classes["root"]} data-open={status} onClick={toggle} />;
}
