import classNames from "classnames";
import React, { CSSProperties } from "react";

import Typography, { ITypo } from "../Typography";
import classes from "./classes.module.scss";

export enum EButtonVariant {
	PRIMARY = "primary",
	SECONDARY = "secondary",
	TERTIARY_YELLOW = "tertiary-yellow",
	TERTIARY_ORANGE = "tertiary-orange",
	TERTIARY_BLUE = "tertiary-blue",
	BLACK = "black",
}

export enum EButtonSize {
	XSMALL = "xsmall",
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large",
}

type IProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	children?: React.ReactNode;
	variant?: EButtonVariant;
	fullwidth?: boolean;
	icon?: JSX.Element | null | false;
	iconstyle?: CSSProperties;
	disabled?: boolean;
	type?: "button" | "submit";
	isloading?: string;
	iconposition?: "left" | "right";
	className?: string;
	sizing?: EButtonSize;
};

export default function Button(props: IProps) {
	let {
		variant = EButtonVariant.PRIMARY,
		sizing = EButtonSize.LARGE,
		disabled = false,
		type = "button",
		isloading = "false",
		fullwidth = false,
		iconposition = "right",
		onClick,
		icon,
		className = "",
	} = props;

	const fullwidthattr = fullwidth.toString();
	const isloadingattr = isloading.toString();

	const attributes = {
		...props,
		sizing,
		variant,
		disabled,
		type,
		isloadingattr,
		fullwidthattr,
	};
	delete attributes.fullwidth;
	delete attributes.icon;
	delete attributes.iconstyle;
	delete attributes.iconposition;
	return (
		<button {...attributes} onClick={onClick} className={classNames(classes["root"], className)} type={type}>
			{icon && iconposition === "left" && icon}
			{props.children}
			{icon && iconposition === "right" && icon}
		</button>
	);
}
