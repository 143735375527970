import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

type IProps = {
	typo: ITypo;
	children: React.ReactNode;
	color?: ITypoColor;
	className?: string;
};

export enum ITypo {
	H1_TITLE = "H1-title",

	H2_TITLE = "H2-title",

	H3_TITLE = "H3-title",

	TEXT_CARD_18 = "text-card-18",
	TEXT_CARD_14 = "text-card-14",

	PARAGRAPH_18_REGULAR = "paragraph-18-regular",
	PARAGRAPH_15_REGULAR = "paragraph-15-regular",
	PARAGRAPH_10_REGULAR = "paragraph-10-regular",
}

export enum ITypoColor {
	WHITE = "var(--color-generic-white)",
	BLACK = "var(--color-generic-black)",
	CYAN = "var(--color-generic-cyan)",
}

export default function Typography(props: IProps) {
	const style = useMemo(() => ({ color: `${props.color}` }), [props.color]);

	return (
		<span className={classnames(styles["container"], styles[props.typo], props.className ?? "")} style={style}>
			{props.children}
		</span>
	);
}
