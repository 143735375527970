import classes from "./styles.module.scss";

type IProps = {
	name: string;
	role: string;
	functions?: string;
	image: string;
	descriptions: string[];
};
export default function TeamMember({ name, role, functions, image, descriptions }: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["background-container"]}>
				<img loading="lazy" className={classes["background"]} src={image} alt="background" />
				<div className={classes["content"]}>
					<div className={classes["name"]}>{name}</div>
					<div className={classes["role"]}>{role}</div>
					{functions && <div className={classes["functions"]}>{functions}</div>}
				</div>
				<div className={classes["hover"]}>
					<ul className={classes["list"]}>
						{descriptions.map((description, i) => (
							<li key={i} className={classes["list-element"]}>
								{description}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}
