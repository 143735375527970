import { useCallback, useState } from "react";
import Anchor from "src/Components/Materials/Anchor";

import Button, { EButtonVariant } from "../../Materials/Button";
import Typography, { ITypo, ITypoColor } from "../../Materials/Typography";
import classes from "./styles.module.scss";

export default function Home() {
	const [trailer, setTrailer] = useState(true);

	const onTrailerClick = useCallback(() => {
		setTrailer(true);
	}, [trailer]);

	const onTeaserClick = useCallback(() => {
		setTrailer(false);
	}, [trailer]);

	let autoPlay = true;
	if (/Android|iPhone/i.test(navigator.userAgent)) {
		autoPlay = false;
	}

	return (
		<>
			<Anchor id="home" />
			<div className={classes["root"]}>
				<video autoPlay muted loop className={classes["background-video"]}>
					<source src="assets/gradient2.webm" type="video/webm" />
				</video>
				<div className={classes["background-container"]}>
					<div className={classes["section-container"]}>
						<div className={classes["top-corners-container"]}>
							<img src="./assets/sections/home/top-left.svg" alt="top left corner" className={classes["top-left-corner"]} />
							<img src="./assets/sections/home/top-right.svg" alt="top right corner" className={classes["top-right-corner"]} />
						</div>
						<div className={classes["section-top"]}>
							<div className={classes["section-pretitle"]}>
								<Typography typo={ITypo.H3_TITLE} color={ITypoColor.WHITE}>
									THEXP.AI
								</Typography>
								<br></br>
								<Typography typo={ITypo.PARAGRAPH_18_REGULAR} color={ITypoColor.WHITE}>
									PRESENTS
								</Typography>
							</div>
							<div className={classes["section-title"]}>World Of The Machines</div>
							<div className={classes["section-subtitle"]}>
								<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
									At the cross of AI, Gaming, NFT and ASMR Fidgets, get a sneak preview of World of the Machines, a fascinating artistic and
									sociological experiment built around the concept of the singularity.
								</Typography>
							</div>
						</div>
						<div className={classes["bottom-corners-container"]}>
							<img src="./assets/sections/home/bottom-left.svg" alt="bottom left corner" className={classes["bottom-left-corner"]} />
							<img src="./assets/sections/home/bottom-right.svg" alt="bottom right corner" className={classes["bottom-right-corner"]} />
						</div>
						{trailer && (
							<div className={classes["section-video"]}>
								<video autoPlay={autoPlay} muted poster="./assets/poster.png" controls className={classes["video"]}>
									<source src={"https://storage.googleapis.com/wotm-prd/Trailer%20v2_1_1.mp4"} type="video/mp4"></source>
								</video>
							</div>
						)}
						{!trailer && (
							<div className={classes["section-video"]}>
								<video autoPlay={autoPlay} muted poster="./assets/poster.png" controls className={classes["video"]}>
									<source src={"https://storage.googleapis.com/wotm-prd/Updated_WOTM%20-%20Generic.mp4"} type="video/mp4"></source>
								</video>
							</div>
						)}

						<div className={classes["section-bottom"]}>
							<Button onClick={onTrailerClick} fullwidth variant={EButtonVariant.PRIMARY}>
								Trailer
							</Button>
							<Button onClick={onTeaserClick} fullwidth variant={EButtonVariant.SECONDARY}>
								Teaser
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
