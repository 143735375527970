import classes from "./styles.module.scss";

type IProps = {
	rounded?: boolean;
	height?: number;
};
export default function RoadmapLine({ rounded = false, height }: IProps) {
	const style: React.CSSProperties = rounded ? { borderRadius: "50px 50px 0 0" } : {};
	if (height) {
		style.height = height;
	}

	return <div className={classes["root"]} style={style} />;
}
