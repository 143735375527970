import Anchor from "src/Components/Materials/Anchor";
import Typography, { ITypo } from "src/Components/Materials/Typography";

import RoadmapStep from "./RoadmapStep";
import RoadmapDot from "./RoadmapStep/RoadmapDot";
import RoadmapLine from "./RoadmapStep/RoadmapLine";
import classes from "./styles.module.scss";

export default function Roadmap() {
	return (
		<div className={classes["root"]}>
			<video autoPlay muted loop className={classes["background-video"]}>
				<source src="assets/gradient2.webm" type="video/webm" />
			</video>
			<video autoPlay muted loop className={classes["background-video2"]}>
				<source src="assets/gradient2.webm" type="video/webm" />
			</video>
			<div className={classes["background-container"]}>
				<div className={classes["section-header"]}>
					<div className={classes["title"]}>
						<Anchor id="roadmap" />
						<Typography typo={ITypo.H2_TITLE}>137 days to save Humanity and create a peaceful bond with the incoming Singularity...</Typography>
					</div>
					<div className={classes["text"]}>
						<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
							Enter this fascinating adventure, and commit yourself to the reflection about how the human species should prepare itself to the
							exponential growth of Artificial Intelligence in its society and the potential power takeover of the impeding Singularity.
						</Typography>
					</div>
				</div>
				<div className={classes["roadmap-container"]}>
					<RoadmapStep
						step={{
							date: "19 July 2023",
							datePosition: "left",
							title: "Secret Dinner",
							text: "OGs and investors secret dinner on the last day of the ETH Conference - Paris",
						}}
						lineRounded
					/>
					<RoadmapStep
						step={{
							date: "20 August 2023",
							datePosition: "right",
							title: "Private Sale",
							text: "Top 5% Meteorite rarity PassForLife - Fidget x 137 super meteorit Limited Edition",
						}}
					/>
					<RoadmapStep
						step={{
							date: "25 August 2023",
							datePosition: "left",
							title: "Seed",
							text: "Seed Round closed",
						}}
					/>
					<RoadmapStep
						step={{
							date: "30 August 2023",
							datePosition: "right",
							title: "Fidget Production",
							text: "Prototypes, capitalisation final validation. Production suppliers final choice, negotiation and factory order",
						}}
					/>
					<RoadmapStep
						step={{
							date: "25 September 2023",
							datePosition: "left",
							title: "PreSale",
							text: "Top10 to top 25% rarity PassForLife - Fidget x 3,500 904L Steel Limited Edition",
						}}
					/>
					<RoadmapStep
						step={{
							date: "10 October 2023",
							datePosition: "right",
							title: "Public Sale",
							text: "Start of the 137 days contes",
						}}
					/>
					<RoadmapStep
						step={{
							date: "End February 2024",
							datePosition: "left",
							title: "Delivery",
							text: "Participants accounts snapshot. Reveal leaderboard calculation",
						}}
					/>
					<div className={classes["last-line"]}>
						<div />
						<div className={classes["dot-container"]}>
							<RoadmapLine />
							<RoadmapDot color="blue" />
						</div>
						<div />
					</div>
					<div className={classes["last-block"]}>
						<div className={classes["title"]}>
							<Typography typo={ITypo.H3_TITLE}>29 February 2024</Typography>
						</div>
						<div className={classes["text"]}>
							<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>Rewards announcement (Winners Leaderboard), and final distribution</Typography>
						</div>
					</div>
					<div className={classes["separator"]}>
						<img src="./assets/sections/roadmap/separator.png" alt="separator" width="100%" />
					</div>
				</div>
			</div>
		</div>
	);
}
