import { useCallback } from "react";
import Button, { EButtonVariant } from "src/Components/Materials/Button";

import classes from "./styles.module.scss";
import TeamMember from "./TeamMember";
import Typography, { ITypo } from "src/Components/Materials/Typography";
import Anchor from "src/Components/Materials/Anchor";

export default function Team() {
	const sendMail = useCallback(() => {
		let email = "contact@thexp.ai";
		location.href = "mailto:" + email;
	}, []);
	return (
		<>
			<div className={classes["root"]}>
				<div className={classes["background-container"]}>
					<div className={classes["section-container"]}>
						<div className={classes["text-container"]}>
							<div className={classes["title"]}>
								<Anchor id="team" />
								<Typography typo={ITypo.H2_TITLE}>Meet Our Dream Team</Typography>
							</div>
							<div className={classes["text"]}>
								<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
									World Of The Machines is a refined complex project, requesting expertise from numerous fields: AI, 3D, mechanical systems,
									product design, watchmaking expertise, Near Field Chips, Tokenomics, Gaming... We've convinced the best to come and complete
									the Experience team:
								</Typography>
							</div>
						</div>
						<div className={classes["members-container"]}>
							<TeamMember
								name="Frédéric Bonelli"
								role="Founder"
								functions="Project Proponent"
								image="./assets/sections/team/Card Team Anim.png"
								descriptions={[
									"Investment advisor for the cryptocurrency hedge fund SpinCrypto Capital from 2019 to 2021",
									"Investing in seed rounds of 70+ start-ups since 2017",
									"Owner of mediascointribune.com and fr.cointelegraph.com as well as a lead contributor to Forbes France",
								]}
							/>
							<TeamMember
								name="Sarah De Lima"
								role="Founder"
								functions="Project Owner"
								image="./assets/sections/team/Card Team Anim-1.png"
								descriptions={[
									"Proven expertise in blockchain, digital assets and Web3 since 2017",
									"Sales VP & Business Development Officer of both medias cointribune.com and fr.cointelegraph.com",
									"Created and managed numerous cutting-edge concepts and services in entertainment, IoT, and retail",
								]}
							/>
							<TeamMember
								name="Adrien Hubert"
								role="Series Advisor"
								functions="Tokenomics, Network & DAO"
								image="./assets/sections/team/Card Team Anim-2.png"
								descriptions={[
									"Founder of Smart-Chain, a consulting and dev company focused on research and innovation specialised in Blockchain (40+ developers)",
									"Previously worked in corporate banking on various blockchain projects",
									"Tokenomics and DAO renowned expert",
									"Degree in market finance and financial modelling from EDHEC",
								]}
							/>
							<TeamMember
								name="Aaron Besnainou"
								role="Series Advisor"
								functions="Network & Marketing"
								image="./assets/sections/team/Card Team Anim-3.png"
								descriptions={[
									"Serial entrepreneur, initiated and nurtured 10+ startups across numerous industries, collectively generating sales exceeding $20M.",
									"His foray into crypto began in 2017. Since then he has joined 50 Partners, the dynamic collective the French Apes, and has co- founded OxyaOrigin",
									"Holds degrees from ESSEC BusinessSchool, Cass Business School, and the University of Michigan",
								]}
							/>
							<TeamMember
								name="Grégoire Jouaneau"
								role="Series Advisor"
								functions="Growth Hacking"
								image="./assets/sections/team/Card Team Anim-4.png"
								descriptions={[
									"Former high-level athlete turned entrepreneur over 10 years ago",
									"Started investingin cryptocurrencies in 2017",
									"Has worked with  various web3.0 projects, Board member of Ledgity (French fintech).",
									"Renowned growth hacking skills",
								]}
							/>
							<TeamMember
								name="Nicolas-Henry Bozzi"
								role="Series Advisor"
								functions="Whales & NFT OGs"
								image="./assets/sections/team/Card Team Anim-5.png"
								descriptions={[
									"Artdealer / advisor, model for Elite agency ",
									'Renowned NFT collector and "stealth" influencer',
									"ConnectedtotheNFTOGsecosystem worldwide (i.e. 200ETH+ NFTs portfolio value collectors)",
									"Member of the most of the secrets /closed NFT groups and networks (NFT Alpha group, the A- List, etc.)",
								]}
							/>
							<TeamMember
								name="Kerel Verwaerde"
								role="WOTM CMO"
								functions="WOTM CMO"
								image="./assets/sections/team/Card Team Anim-11.png"
								descriptions={[
									"Founder and CEO of NFTING",
									"Marketer and operations manager at heart, in blockchain since 2017 in exchanges, DeFi, Gamefi, and now NFTs",
									"Big believer in web3.0, its mission, potential impact, and world-changing future.",
								]}
							/>
						</div>
						<div className={classes["partners-container"]}>
							<div className={classes["partners-title"]}>
								<Typography typo={ITypo.H2_TITLE}>Partners</Typography>
							</div>
							<div className={classes["partners"]}>
								<TeamMember
									name="Diego Böttger-Zevallos"
									role="Fidget Development"
									image="./assets/sections/team/Card Team Anim-6.png"
									descriptions={[
										"CEO of TempStation and product consultant (design, development, production and distribution) specialised in middle to high-end and luxury goods: timepieces (Swiss Made, Asian made, diamonds, mechanical and quartz movement).",
										"More than 15 years experience in the watch industry",
									]}
								/>
								<TeamMember
									name="Jean-Baptiste Fraysse"
									role="Mechanical engineer"
									functions="3D modelist"
									image="./assets/sections/team/Card Team Anim-7.png"
									descriptions={[
										"Jean-Baptiste is a Swiss army knife engineer specialising in the prototyping and industrialisation of connected mechatronic devices",
										'After 10 years of multiple professional experiences, he is currently working on a doctorate with the theme "Frugal innovation applied to intensive care medicine".',
									]}
								/>
								<TeamMember
									name="Christopher Fernandes"
									role="3D Design & UI"
									image="./assets/sections/team/Card Team Anim-8.png"
									descriptions={[
										"Designer specialising in digital art, static and animated 3D offering branding, illustration and web design services",
										"Renowned for the design of several Series NFTs (3D renderering by layers)",
										"Creation of a monumental 16m saucer (largest musical instrument in the world) for the artist Patrice Moullet",
									]}
								/>
								<TeamMember
									name="Remy Painchaut"
									role="DGA Engineer - Cryptography"
									image="./assets/sections/team/Card Team Anim-9.png"
									descriptions={[
										"Engineer",
										"Crypto enthusiastic engineer that started investing in 2021 in various French community crypto projects",
										"Cyphering algorithms designer",
									]}
								/>
								<TeamMember
									name="Antoine Bonelli"
									role="AI consultant & music producer"
									image="./assets/sections/team/Card Team Anim-10.png"
									descriptions={[
										"Proven expertise in Artificial Intelligence",
										"Senior Consult ant for AI builders",
										"Multiple instruments player",
										"Digital music producer",
									]}
								/>
							</div>
						</div>
						<div className={classes["logos-container"]}>
							<div className={classes["logo-div"]}>
								<a href="https://epok-design.fr" target="_blank">
									<img loading="lazy" className={classes["logo"]} src="./assets/sections/team/partners/epok.svg" alt="epok logo" />
								</a>
							</div>
							<div className={classes["logo-div"]}>
								<a href="https://www.smart-chain.fr" target="_blank">
									<img
										loading="lazy"
										className={classes["logo"]}
										src="./assets/sections/team/partners/smartchain.svg"
										alt="smartchain logo"
									/>
								</a>
							</div>
							<div className={classes["logo-div"]}>
								<a href="https://www.slime.marketing" target="_blank">
									<img loading="lazy" className={classes["logo"]} src="./assets/sections/team/partners/slime.svg" alt="slime logo" />
								</a>
							</div>
							<div className={classes["logo-div"]}>
								<a href="https://www.ferrstudio.ch" target="_blank">
									<img loading="lazy" className={classes["logo"]} src="./assets/sections/team/partners/ferr.svg" alt="ferr logo" />
								</a>
							</div>
						</div>
					</div>
					<div className={classes["lets-connect"]}>
						<div className={classes["title"]}>
							<Typography typo={ITypo.H2_TITLE}>Let's connect</Typography>
						</div>
						<div className={classes["text"]}>
							<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
								We would love to hear from you. Whether you have questions, partnership opportunities, or simply want to learn more about
								TheExperience.
							</Typography>
						</div>
						<div className={classes["button-container"]}>
							<Button variant={EButtonVariant.PRIMARY} onClick={sendMail}>
								contact@thexp.ai
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
