import { EStatus, useMobileMenuContext } from "src/Contexts/MobileMenuContext";
import classes from "./style.module.scss";
import { useCallback, useEffect } from "react";
import Button, { EButtonSize, EButtonVariant } from "src/Components/Materials/Button";
import Typography, { ITypo, ITypoColor } from "src/Components/Materials/Typography";

export default function HeaderMenu() {
	const { status, setStatus } = useMobileMenuContext();

	const toggle = useCallback(() => {
		if (status === EStatus.OPEN) {
			return setStatus(EStatus.CLOSED);
		} else {
			return setStatus(EStatus.OPEN);
		}
	}, [status]);

	const goToWishlistHash = useCallback(() => {
		window.location.hash = "wishlist";
	}, []);

	const hashChange = useCallback(() => {
		if (status === EStatus.OPEN) setStatus(EStatus.CLOSED);
	}, [status]);

	useEffect(() => {
		window.addEventListener("hashchange", hashChange);
		return () => window.removeEventListener("hashchange", hashChange);
	});

	return (
		<div className={classes["root"]} data-open={status}>
			<div className={classes["background"]} onClick={toggle}></div>
			<div className={classes["content"]}>
				<div className={classes["links-container"]}>
					<a href="#wishlist-top" className={classes["link"]}>
						TheXP
					</a>
					<a href="#home" className={classes["link"]}>
						World Of The Machine
					</a>
					<a href="#features" className={classes["link"]}>
						Innovations & world first
					</a>
					<a href="#team" className={classes["link"]}>
						Team
					</a>
					<a href="#tokenomics" className={classes["link"]}>
						Mint Revenues Breakdown
					</a>
					<a href="#roadmap" className={classes["link"]}>
						Roadmap
					</a>
				</div>
				<div className={classes["connect-button"]}>
					<Button onClick={goToWishlistHash} variant={EButtonVariant.PRIMARY} sizing={EButtonSize.SMALL}>
						Join the wishlist
					</Button>
				</div>
			</div>
		</div>
	);
}
