import { useState } from "react";
import styles from "./styles.module.scss";
import Anchor from "src/Components/Materials/Anchor";
import Typography, { ITypo, ITypoColor } from "src/Components/Materials/Typography";

export default function Tokenomics() {
	return (
		<>
			<div className={styles["root"]}>
				<div className={styles["background-container"]}>
					<div className={styles["section-container"]}>
						<div className={styles["content-container"]}>
							<div className={styles["content-title"]}>
								<Anchor id="tokenomics" />
								<Typography typo={ITypo.H2_TITLE} color={ITypoColor.WHITE}>
									A Foundation and a DAO Fund focused on Artificial Intelligence
								</Typography>
							</div>
							<div className={styles["content-text-first"]}>
								<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
									The majority of the Mint revenues will be redistributed to participants in the form of :
									<br />
									• The limited edition connected metal Fidget* complementary to the Pass For Life NFT.
									<br />• Numerous short-term (weekly) and Final Reveal rewards. <br />
									<br />
									But that's not all: Should the Series be successful enough (i.e. cross a predefined threshold,) the team aims to seed a
									Swiss based Foundation and a DAO Fund focused on Artificial Intelligence, and settle a bonus airdrop in the form of some AI
									Fund token rewarding the best participants.
								</Typography>
							</div>
							<div className={styles["content-title"]}>
								<Typography typo={ITypo.H3_TITLE} color={ITypoColor.WHITE}>
									1. TheXP Foundation
								</Typography>
							</div>
							<div className={styles["content-text"]}>
								<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
									Missions:
									<br /> - Support evangelization about Artificial Intelligence and the concept of Singularity toward the public in order to
									provide the requested knowledge if they aim to think efficiently and make the right decision
									<br />- Structure an AI Ethical label, like the one developed to frame Research and Development in the field of Human Genome
								</Typography>
							</div>
							<div className={styles["content-title"]}>
								<Typography typo={ITypo.H3_TITLE} color={ITypoColor.WHITE}>
									2. TheXP Fund
								</Typography>
							</div>
							<div className={styles["content-text"]}>
								<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>
									A DAO Fund investing in AI based startups which are following the AI Ethical label.
								</Typography>
							</div>
							<img loading="lazy" width="100%" src="./assets/sections/tokenomics.png" alt="Large Image" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
