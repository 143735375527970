import { useCallback } from "react";
import BlueBlock from "../BlueBlock";
import DarkBlock from "../DarkBlock";
import classes from "./styles.module.scss";
import RoadmapDot from "./RoadmapDot";
import RoadmapLine from "./RoadmapLine";

type IStep = {
	date: string;
	title: string;
	text: string;
	datePosition: "left" | "right";
};

type IProps = {
	step: IStep;
	lineRounded?: boolean;
};

export default function RoadmapStep({ step, lineRounded = false }: IProps) {
	const getBlock = useCallback((where: "left" | "right") => {
		if ((where === "left" && step.datePosition === "left") || (where === "right" && step.datePosition === "right")) {
			return <BlueBlock title={step.date} align={step.datePosition === "left" ? "flex-end" : "flex-start"} />;
		} else {
			return <DarkBlock title={step.title} text={step.text} />;
		}
	}, []);

	return (
		<div className={classes["root"]}>
			<div className={classes["roadmap-line-desktop"]}>
				{getBlock("left")}
				<div className={classes["dot-container"]}>
					<RoadmapLine rounded={lineRounded} />
					<RoadmapDot />
					<RoadmapLine />
				</div>
				{getBlock("right")}
			</div>
			<div className={classes["roadmap-line-mobile"]}>
				<RoadmapLine rounded={lineRounded} />
				<RoadmapDot />
				<RoadmapLine />
				<BlueBlock title={step.date} align={step.datePosition === "left" ? "flex-end" : "flex-start"} />
				<RoadmapLine />
				<DarkBlock title={step.title} text={step.text} />
				<RoadmapLine height={150} />
			</div>
		</div>
	);
}
