import classes from "./styles.module.scss";

type IProps = {
	title: string | JSX.Element;
	subtitle: string | JSX.Element;
	picto: string;
};
export default function FeatureContainer({ title, subtitle, picto }: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["background-container"]}>
				<div className={classes["content"]}>
					<img className={classes["picto"]} src={picto} alt="background" />
					<div className={classes["title"]}>{title}</div>
					<div className={classes["subtitle"]}>{subtitle}</div>
				</div>
			</div>
		</div>
	);
}
