import Typography, { ITypo } from "src/Components/Materials/Typography";

import classes from "./styles.module.scss";

type IProps = {
	title: string;
	text: string;
};

export default function DarkBlock({ title, text }: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["container"]}>
				<div className={classes["title"]}>
					<Typography typo={ITypo.H3_TITLE}>{title}</Typography>
				</div>
				<div className={classes["text"]}>
					<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>{text}</Typography>
				</div>
			</div>
		</div>
	);
}
