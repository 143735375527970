import { useState } from "react";

import { EStatus, MobileMenuContext } from "../../Contexts/MobileMenuContext";
import Features from "../Sections/Features";
import Home from "../Sections/Home";
import Partners from "../Sections/Partners";
import Roadmap from "../Sections/Roadmap";
import Team from "../Sections/Team";
import Tokenomics from "../Sections/Tokenomics";
import WishlistBottom from "../Sections/WishlistBottom";
import WishlistTop from "../Sections/WishlistTop";
import Content from "../Structure/Content";
import Footer from "../Structure/Footer";
import Header from "../Structure/Header";
import HeaderMenu from "../Structure/HeaderMenu";

import "./globals.scss";

export default function Main() {
	const [status, setStatus] = useState(EStatus.NOTHING);

	return (
		<MobileMenuContext.Provider value={{ status, setStatus }}>
			<Header />
			<Content>
				<Home />
				<WishlistTop />
				<Features />
				<Roadmap />
				<Tokenomics />
				<Team />
				<Partners />
				<WishlistBottom />
			</Content>
			<Footer />
			<HeaderMenu />
		</MobileMenuContext.Provider>
	);
}
