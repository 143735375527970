import styles from "./styles.module.scss";
import mailchimpBackground from "../../../Assets/mailchimpBackground.png";
import Typography, { ITypo, ITypoColor } from "../../../Components/Materials/Typography";
import Anchor from "../../Materials/Anchor";

export default function WishlistBottom() {
	return (
		<>
			<Anchor id="wishlist" />
			<div
				className={styles["root"]}
				style={{
					backgroundImage: `url(${mailchimpBackground})`,
					width: "100%",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}>
				<div className={styles["background-container"]}>
					<div className={styles["section-container"]}>
						<div className={styles["section-title"]}>
							<Typography typo={ITypo.H2_TITLE} color={ITypoColor.WHITE}>
								Join the Wishlist
							</Typography>
						</div>
						<div className={styles["section-subtitle"]}>
							<Typography typo={ITypo.PARAGRAPH_15_REGULAR}>I'm In ! Keep me informed about the project.</Typography>
						</div>
						<iframe className={styles["newsletter-frame"]} src={"./mailchimp.html"} title="mailChimp" />
					</div>
				</div>
			</div>
		</>
	);
}
