import { useCallback } from "react";

import classes from "./style.module.scss";

export default function Footer() {
	const sendMail = useCallback(() => {
		let email = "contact@thexp.ai";
		location.href = "mailto:" + email;
	}, []);
	return (
		<div className={classes["footer"]}>
			<div className={classes["footer-container"]}>
				<div className={classes["part"]}>
					<div className={classes["part-logo"]}>
						<img loading="lazy" src="./assets/logo.png" alt="website logo" />
					</div>
					<div className={classes["part-text"]}>
						The Experience is a collective of AI, Web3, marketing and retail veteran who aim to craft exclusive projects supporting the coming 4th
						industrial revolution.
					</div>
				</div>
				<div className={classes["separator"]} />
				<div className={classes["part"]}>
					<div className={classes["part-title"]}>Section</div>
					<div className={classes["part-links"]}>
						<a href="#home" className={classes["link"]}>
							TheXP
						</a>
						<a href="#wishlist-top" className={classes["link"]}>
							World Of The Machine
						</a>
						<a href="#features" className={classes["link"]}>
							Innovation & World First
						</a>
						<a href="#roadmap" className={classes["link"]}>
							Roadmap
						</a>
						<a href="#team" className={classes["link"]}>
							Team & partners
						</a>
						<a href="#tokenomics" className={classes["link"]}>
							Mint Revenues Breakdown
						</a>
					</div>
				</div>
				<div className={classes["separator"]} />
				<div className={classes["part"]}>
					<div className={classes["part-title"]}>Contact Us</div>
					<div className={classes["part-links"]}>
						<a onClick={sendMail} className={classes["link"]}>
							contact@thexp.ai
						</a>
						<a href="https://twitter.com/TheXP_ai" target="_blank" className={classes["link"]}>
							Twitter
						</a>
					</div>
				</div>
				<div className={classes["separator"]} />
				<div className={classes["part"]}>
					<div className={classes["part-title"]}>Helpful-Links</div>
					<div className={classes["part-links"]}>
						<a href="https://storage.googleapis.com/wotm-prd/Terms_and_conditions.pdf" target="_blank" className={classes["link"]}>
							Terms & Conditions
						</a>
						<a href="https://storage.googleapis.com/wotm-prd/Privacy_Policy.pdf" target="_blank" className={classes["link"]}>
							Privacy Policy
						</a>
					</div>
				</div>
			</div>
			<div className={classes["rights"]}>©2023 Theexperience. All rights reserved.</div>
		</div>
	);
}
